

































































































































import { SfImage } from '@storefront-ui/vue';
import SfButton from '~/components/StorefrontUi/SfButton.vue';
import {
  defineComponent, ref, useFetch,
} from '@nuxtjs/composition-api';
import { useImage, useUiHelpers } from '~/composables';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import { addBasePath } from '~/helpers/addBasePath';
import { getCategoryImage } from '~/helpers/getCategoryImage';
import CategoryToggleMenu from '~/components/CategoryToggleMenu.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { debounce } from 'lodash-es';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import ImageWrapper from '~/components/image/ImageWrapper.vue';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'CategoriesTilesHoverSection',
  components: {
    SfButton,
    SfImage,
    ImageWrapper,
    CategoryToggleMenu,
    SkeletonLoader,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { addTags } = useCache();
    const { search, result, loading } = useCategorySearch();
    const { imageSizes, getMagentoImage } = useImage();
    const { getCatLink } = useUiHelpers();
    const activeCategory = ref(null);
    const { isAuthenticated } = useUser();
    const onMouseOver = debounce((category) => {
      activeCategory.value = category;
    }, 250);
    const isToggleMobileMenu = ref(true);
    const toggleMobileMenu = () => {
      isToggleMobileMenu.value = !isToggleMobileMenu.value;
    };
    const fetchedCategories = ref({});
    const handleCategoriesFetched = (id: string, categories: any[]) => {
      fetchedCategories.value[id] = categories;
    };

    useFetch(async () => {
      await search({
        pageSize: 32,
        filters: {
          parent_id: {
            eq: '154', // 154 is the id of the uprawa category
          },
        },
      });

      if (!isAuthenticated.value) {
        const categoriesTags = result.value?.map((category) => ({
          prefix: CacheTagPrefix.Category,
          value: category.uid,
        }));

        addTags(categoriesTags);
      }
    });

    const onMouseLeave = () => {
      activeCategory.value = null;
    };

    return {
      onMouseLeave,
      activeCategory,
      onMouseOver,
      getCatLink,
      productGetters,
      imageSizes,
      addBasePath,
      getCategoryImage,
      searchedCategories: result || {},
      searchedCategoriesLoading: loading,
      isToggleMobileMenu,
      toggleMobileMenu,
      fetchedCategories,
      handleCategoriesFetched,
      getMagentoImage,
    };
  },
});
