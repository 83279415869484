import { render, staticRenderFns } from "./CategoryToggleMenu.vue?vue&type=template&id=03f13b86&scoped=true&"
import script from "./CategoryToggleMenu.vue?vue&type=script&lang=ts&"
export * from "./CategoryToggleMenu.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryToggleMenu.vue?vue&type=style&index=0&id=03f13b86&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f13b86",
  null
  
)

export default component.exports